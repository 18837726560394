// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  API_URL : "https://api.amplifynature.com/index.php",
  production: false,
  firebase: {
    // apiKey: "AIzaSyALBo0zXY00iruzrhyk28sVd27SIFT2AIE",
    // authDomain: "amplifynature-sol-staging.firebaseapp.com",
    // databaseURL: "https://amplifynature-sol-staging-default-rtdb.firebaseio.com",
    // projectId: "amplifynature-sol-staging",
    // storageBucket: "amplifynature-sol-staging.appspot.com",
    // messagingSenderId: "215811261098",
    // appId: "1:215811261098:web:3ca71f52efe6c5178406d3"
    // apiKey: "AIzaSyA7Qb5rLJwzr7Mohvj-vqSzE4fZ5nkcEx0",
    // authDomain: "amplifynature-sol-5b005.firebaseapp.com",
    // databaseURL: "https://amplifynature-sol-5b005-default-rtdb.firebaseio.com",
    // projectId: "amplifynature-sol-5b005",
    // storageBucket: "amplifynature-sol-5b005.appspot.com",
    // messagingSenderId: "175553190905",
    // appId: "1:175553190905:web:8978f82d188a857f14bb28"
    apiKey: "AIzaSyCOKbbogp9g1i-z-Ako82h_FZ8jcxIYNkc",
    authDomain: "sol2023-d0cb7.firebaseapp.com",
    databaseURL: "https://sol2023-d0cb7-default-rtdb.firebaseio.com",
    projectId: "sol2023-d0cb7",
    storageBucket: "sol2023-d0cb7.appspot.com",
    messagingSenderId: "85092169376",
    appId: "1:85092169376:web:89d134dccfe04db2033bc5"
  },
  mapbox: {
    // accessToken: 'pk.eyJ1IjoidHNpb3giLCJhIjoiY2p0NGV1Z3dlMTBjbzQzcGdrbjRjNmU1NSJ9.Dsvl1gPtmb3lxNSaK5x7ZQ'
    accessToken: 'pk.eyJ1IjoiZmRlcnJpZW4iLCJhIjoiY2pwbDV1MHAwMDB4NTQzcXRjb2gwZG0zdiJ9.nSWWg_F0u0FL3aHw41Ealg'
  },
  // baseUrlApi: 'http://localhost/amplify-api/index.php',
  // urlapisol: 'http://localhost/amplify-api/index.php/ApiSol',
  // urlapidiscours: 'http://localhost/amplify-api/index.php/Api',
  urlapisol: 'https://api.amplifynature.com/index.php/ApiSol',
  urlapidiscours: 'https://api.amplifynature.com/index.php/Api',
  baseUrlApi: 'https://api.amplifynature.com/index.php',
}; 


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
