import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate() {
        let user = JSON.parse(localStorage.getItem('user'));
        // console.log("AUTH GUARD %o", user);
        if(user){
            return true;
        }

        this.router.navigate(['/login']);
        return false;
    }
}
