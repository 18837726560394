import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	public user:any = {};
    
    constructor(
        private db: AngularFireDatabase,
        private auth: AngularFireAuth,) {
    }

    ngOnInit(){
        if(this.auth.auth.currentUser){
            this.db.database.ref("clients").orderByChild("mail").equalTo(this.auth.auth.currentUser.email).once("value", function(snapshot){
                if(snapshot.val()){
                    for(let i in snapshot.val()){
                        this.user = snapshot.val()[i];
                        break;
                    }
                }else{
                    this.router.navigate(['/login']);
                }
                
            });

        }
    }
}
